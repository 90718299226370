<template>
  <div class="layer-wrapper" :style="layerStyle">
    <component
      v-for="(comp, key) in data.elements"
      :key="key"
      :data="comp"
      :show="showStatus"
      :is="compList[comp.type]"
      :width="layerWidth"
      :height="layerheight"
      :scale="scale"
      :edit="edit"
      :isActive="activeId === key"
      v-bind="uniqueParams(comp.type, key + '')"
      @dragActive="$emit('elementActive', { id: key, layerId: data.id, type: comp.type })"
      @resize="$emit('resize', { id: key, ...$event })"
    ></component>
  </div>
</template>
<script setup lang="ts">
import { PropType, markRaw } from 'vue';
import Block from '@/overlay/components/elements/element-rect.vue';
import Text from '@/overlay/components/elements/element-text.vue';
import Image from '@/overlay/components/elements/element-image.vue';
import Time from '@/overlay/components/elements/element-time.vue';
import Score from '@/overlay/components/elements/element-score.vue';
import Marquee from '@/overlay/components/elements/element-marquee.vue';
import { ILayerOutput } from '@/overlay/types/app';
const props = defineProps({
  data: {
    type: Object as PropType<ILayerOutput>,
    require: true,
    default: () => ({}),
  },
  index: {
    type: Number,
    require: true,
  },
  scale: {
    type: Number,
    require: true,
    default: 1,
  },
  activeId: {
    type: String,
    require: true,
    default: '',
  },
  edit: {
    type: Boolean,
    default: false,
  },
  size: {
    type: Array as PropType<number[]>,
    default: () => [1920, 1080],
  },
  autoTime: {
    type: Boolean,
    default: false,
  },
});
const { data, index, scale, activeId, edit, size, autoTime } = toRefs(props);
const compList = reactive<{
  [key: string]: any;
}>({
  text: markRaw(Text),
  rect: markRaw(Block),
  image: markRaw(Image),
  time: markRaw(Time),
  score: markRaw(Score),
  marquee: markRaw(Marquee),
});

const showStatus = computed(() => {
  return typeof data.value.show === 'boolean' ? data.value.show : JSON.parse(data.value.show);
});

const layerStyle = computed(() => {
  return {
    zIndex: showStatus.value ? (index!.value as number) + 1 : 0,
    top: data.value.transform.y + '%',
    left: data.value.transform.x + '%',
    width: data.value.transform.width + '%',
    height: data.value.transform.height + '%',
    transform: `rotate(${data.value.transform.rotate || 0}deg) skew(${
      data.value.transform.skewX || 0
    }deg,${data.value.transform.skewY || 0}deg)`,
    border: activeId.value && activeId.value === data.value.id ? '4px solid #fff' : 'none',
  };
});
const layerWidth = computed(() => {
  return (size.value[0] * data.value.transform.width) / 100;
});
const layerheight = computed(() => {
  return (size.value[1] * data.value.transform.height) / 100;
});

/** 每个类型的独特参数 */
const uniqueParams = (type: string, id: string) => {
  if (type === 'time') {
    return {
      autoTime: autoTime.value,
    };
  }
  if (type === 'marquee') {
    return {
      id: id + '_' + data.value.id,
    };
  }
};
</script>
<style lang="scss" scoped>
.layer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  perspective: 5000px;

  :deep(.content-container) {
    z-index: 0;
  }

  :deep(.vdr) {
    &.active {
      cursor: move;
    }
  }
}
</style>
