<template>
  <div :id="layerId" class="output-container" :style="{ backgroundColor: background }">
    <div :id="wrapperId" class="output-wrapper" :style="wrapperStyle">
      <template v-for="(layer, i) in layerList" :key="layer.id + reIndex">
        <Layer
          :index="layer.index ? layer.index : i"
          :data="layer"
          :scale="layerScale"
          :edit="activeSelect && activeSelect.type !== ElementType.Layer"
          :activeId="activeSelect?.id"
          :size="wrapperSize"
          :autoTime="autoTime"
          @elementActive="$emit('elementActive', $event)"
          @resize="$emit('resize', { index: layerList.length - i - 1, ...$event })"
        />
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { PropType } from 'vue';
import { cloneDeep } from 'lodash';
import Layer from '@/overlay/components/layer/index.vue';
import { ILayerOutput } from '@/overlay/types/app';
import { IActiveSelect, ElementType } from '@/overlay/types/template';

const resizeElement = (container: HTMLElement, content: HTMLElement, wrapper?: HTMLElement) => {
  let layerScale = 1;
  if (
    container.clientWidth / container.clientHeight >
    wrapperSize.value[0] / wrapperSize.value[1]
  ) {
    content.style.height = container.clientHeight + 'px';
    content.style.width =
      (container.clientHeight / wrapperSize.value[1]) * wrapperSize.value[0] + 'px';
    content.style.top = '0px';
    content.style.left = (container.clientWidth - content.clientWidth) / 2 + 'px';
    if (wrapper) {
      layerScale = container.clientHeight / wrapperSize.value[1];
      wrapper.style.transform = `scale(${layerScale})`;
    }
  } else {
    content.style.width = container.clientWidth + 'px';
    content.style.height =
      (container.clientWidth / wrapperSize.value[0]) * wrapperSize.value[1] + 'px';
    content.style.left = '0px';
    content.style.top = (container.clientHeight - content.clientHeight) / 2 + 'px';
    if (wrapper) {
      layerScale = container.clientWidth / wrapperSize.value[0];
      wrapper.style.transform = `scale(${layerScale})`;
    }
  }
  return layerScale;
};

const props = defineProps({
  data: {
    type: Array as PropType<ILayerOutput[]>,
    require: true,
    default: () => [],
  },
  containerId: {
    type: String,
    default: '',
  },
  activeSelect: {
    type: Object as PropType<IActiveSelect>,
  },
  background: {
    type: String,
    default: 'transparent',
  },
  size: {
    type: String,
    default: '1902*1080',
  },
  autoTime: {
    type: Boolean,
    default: false,
  },
});

const { data, containerId, activeSelect, background, size, autoTime } = toRefs(props);

watch(size, () => {
  resizeHandle();
  layerReset();
});

//改变分辨率，改变key，强制重新渲染
const reIndex = ref(0);
const layerReset = () => {
  reIndex.value++;
};

const wrapperSize = computed(() => {
  const shape: any = size.value.split('*');
  shape[0] = Number(shape[0]);
  shape[1] = Number(shape[1]);
  return shape;
});

const wrapperStyle = computed(() => {
  return {
    width: wrapperSize.value[0] + 'px',
    height: wrapperSize.value[1] + 'px',
    marginTop: `-${wrapperSize.value[1] / 2}px`,
    marginLeft: `-${wrapperSize.value[0] / 2}px`,
  };
});

const layerList = computed(() => {
  const layerCopy = cloneDeep(data.value);
  return layerCopy.reverse();
});

const layerScale = ref(1);

let outContainer: HTMLElement | null = null;
let container: HTMLElement | null = null;
let wrapper: HTMLElement | null = null;
const resizeHandle = () => {
  layerScale.value = resizeElement(outContainer!, container!, wrapper!);
};

const layerId = ref('output-container');
const wrapperId = ref('output-wrapper');

onMounted(async () => {
  layerId.value = 'output-container' + containerId.value;
  wrapperId.value = 'output-wrapper' + containerId.value;
  outContainer = containerId.value ? document.getElementById(containerId.value) : document.body;
  await nextTick();
  container = document.getElementById(layerId.value);
  wrapper = document.getElementById(wrapperId.value);
  resizeHandle();
  window.addEventListener('resize', () => {
    resizeHandle();
  });
});
</script>
<style lang="scss" scoped>
.output-container {
  position: relative;
  overflow: hidden;

  .output-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}
</style>
