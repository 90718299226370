<script setup lang="ts">
const props = defineProps({
  width: {
    type: Number,
    require: true,
    default: 800,
  },
  outerWidth: {
    type: Number,
    default: 18,
  },
  innerWidth: {
    type: Number,
    default: 7,
  },
  offset: {
    type: Number,
    default: 50,
  },
  outerColor: {
    type: String,
    default: '#ffffff',
  },
  innerColor: {
    type: String,
    default: '#ff0043',
  },
  baseColor: {
    type: String,
    default: '#ffffff26',
  },
});

const { width, outerWidth, innerWidth, offset, outerColor, innerColor, baseColor } = toRefs(props);

const baseCircleStyle = computed(() => {
  return {
    width: width.value + 'px',
    height: width.value + 'px',
  };
});

const outerRadius = computed(() => {
  return (width.value - outerWidth.value) / 2;
});

const outerArray = computed(() => {
  return Number(((2 * Math.PI * outerRadius.value + 1) / 2).toFixed(2));
});

const innerRadius = computed(() => {
  return (width.value - outerWidth.value) / 2 - offset.value;
});

const innerArray = computed(() => {
  return Number(((2 * Math.PI * innerRadius.value + 1) / 2).toFixed(2));
});

watch(
  width,
  () => {
    const style = document.styleSheets[0];
    const position1 = `transform: rotate(0deg);`;
    const position2 = `transform: rotate(360deg);`;
    style.insertRule(
      `@keyframes time-progress-rotation { 0% { ${position1} } 100% { ${position2} } }`,
    );
    style.insertRule(
      `@keyframes time-progress-reverse-rotation { 0% { ${position2} } 100% { ${position1} } }`,
    );
    console.log('add style', style);
  },
  {
    immediate: true,
  },
);
</script>

<template>
  <div class="circle-progress" :style="baseCircleStyle">
    <svg>
      <!-- 外环 -->
      <circle
        :r="outerRadius"
        :cx="width / 2"
        :cy="width / 2"
        fill="none"
        :stroke-width="outerWidth"
        :stroke="baseColor"
      />
      <circle
        class="outerCircle"
        :r="outerRadius"
        :cx="width / 2"
        :cy="width / 2"
        fill="none"
        :stroke-width="outerWidth"
        :stroke="outerColor"
        :stroke-dasharray="outerArray"
      />
      <!-- 内环 -->
      <circle
        :r="innerRadius"
        :cx="width / 2"
        :cy="width / 2"
        fill="none"
        :stroke-width="innerWidth"
        :stroke="baseColor"
      />
      <circle
        class="innerCircle"
        :r="innerRadius"
        :cx="width / 2"
        :cy="width / 2"
        fill="none"
        :stroke-width="innerWidth"
        :stroke="innerColor"
        :stroke-dasharray="innerArray"
      />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.circle-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -55%);

  svg {
    width: 100%;
    height: 100%;
  }

  .outerCircle {
    transform-origin: center;
    animation: time-progress-rotation 2s linear infinite;
  }

  .innerCircle {
    transform-origin: center;
    animation: time-progress-reverse-rotation 1.5s linear infinite;
  }
}
</style>
