<template>
  <transition @enter="enter" @leave="leave" :css="false" appear v-if="edit">
    <VueDragResize
      v-if="show && elementShow"
      :parentScaleX="scale"
      :parentScaleY="scale"
      :parentW="width"
      :parentH="height"
      :w="draggableSize.w"
      :h="draggableSize.h"
      :x="draggableSize.x"
      :y="draggableSize.y"
      :isActive="isActive"
      :preventActiveBehavior="true"
      :z="data.index"
      :minw="0"
      :minh="0"
      @clicked="handleDragClick"
      @resizing="handleDragResize"
      @dragging="handleDragResize"
      :style="{
        transformOrigin: data.animation.transformOrigin
          ? data.animation.transformOrigin
          : 'center center',
      }"
    >
      <div class="text-wrapper" :style="textStyle">
        <span :class="{ 'slide-box': animated }">{{ content }}</span>
      </div>
    </VueDragResize>
  </transition>
  <transition @enter="enter" @leave="leave" :css="false" appear v-else>
    <div
      class="text-wrapper"
      v-if="show && elementShow"
      :style="textStyle"
      @click="handleDragClick"
    >
      <span :class="{ 'slide-box': animated }">{{ content }}</span>
    </div>
  </transition>
</template>
<script lang="ts" setup>
import { PropType } from 'vue';
import HandleAnimation from './animation';
import dragResize from './dragResize';
import HandleShadow from './shadow';
import VueDragResize from 'vue-drag-resize';
import { IResizeData, IElement } from '@/overlay/types/template';

const props = defineProps({
  data: {
    type: Object as PropType<IElement>,
    require: true,
    default: () => ({}),
  },
  show: {
    type: Boolean,
    require: true,
    default: false,
  },
  scale: {
    type: Number,
    require: true,
  },
  width: {
    type: Number,
    require: true,
    default: 1920,
  },
  height: {
    type: Number,
    require: true,
    default: 1080,
  },
  isActive: {
    type: Boolean,
    require: true,
    default: false,
  },
  edit: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (e: 'resize', value: IResizeData): void;
  (e: 'dragActive'): void;
}>();

const { data, show, scale, width, height, isActive, edit } = toRefs(props);
const content = ref<string>(data.value.params.content);
/** 控制内容延迟改变 */
let delayTimer: any = null;
/** 控制动画开始时间 */
let animateTimer: any = null;
/** 控制动画结束 */
let endTimer: any = null;
/** 是否展示动画 */
const animated = ref(false);

watch(
  () => data.value.params.content,
  val => {
    animated.value = false;
    if (endTimer) {
      clearTimeout(endTimer);
    }
    endTimer = setTimeout(() => {
      animated.value = false;
    }, 900);
    if (animateTimer) {
      clearTimeout(animateTimer);
    }
    animateTimer = setTimeout(() => {
      animated.value = true;
    }, 300);
    if (delayTimer) {
      clearTimeout(delayTimer);
    }
    delayTimer = setTimeout(() => {
      content.value = val;
    }, 600);
  },
);

const elementShow = computed(() => {
  return typeof data.value.show === 'boolean' ? data.value.show : JSON.parse(data.value.show);
});

const isBolder = computed(() => {
  return typeof data.value.params.isBolder === 'boolean'
    ? data.value.params.isBolder
    : JSON.parse(data.value.params.isBolder);
});

const textStyle = computed(() => {
  const style: any = {
    color: data.value.params.color,
    fontFamily: data.value.params.fontFamily,
    textAlign: data.value.params.textAlign,
    'font-weight': isBolder.value ? 'bolder' : 'normal',
    lineHeight: +data.value.params.lineHeight,
    fontStyle: data.value.params.fontStyle,
    fontSize: getTextFontSize(),
    zIndex: data.value.index,
    transform: `rotate(${data.value.transform.rotate || 0}deg) skew(${
      data.value.transform.skewX || 0
    }deg,${data.value.transform.skewY || 0}deg)`,
    boxShadow: shadow.value,
    textShadow: textShadow.value,
    transformOrigin: data.value.animation.transformOrigin
      ? data.value.animation.transformOrigin
      : 'center center',
  };
  if (!edit.value) {
    style.width = data.value.transform.width + '%';
    style.height = data.value.transform.height + '%';
    style.top = data.value.transform.y + '%';
    style.left = data.value.transform.x + '%';
  }
  return style;
});

const getTextFontSize = () => {
  const size =
    (height.value * data.value.transform.height) /
      100 /
      data.value.params.lines /
      +data.value.params.lineHeight +
    'px';
  return size;
};

const { enter, leave } = HandleAnimation(data, width, height);
const { shadow, textShadow } = HandleShadow(data);
const { draggableSize, handleDragResize, handleDragClick } = dragResize(data, width, height, emit);
</script>
<style lang="scss" scoped>
.text-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  white-space: pre-wrap;
  opacity: 1;

  span {
    flex: 1;
  }

  .slide-box {
    animation: slide 0.6s linear;
  }
}

@keyframes slide {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  50% {
    opacity: 1;
    transform: translateY(-100%);
  }

  60% {
    opacity: 0;
    transform: translateY(-100%);
  }

  61% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
