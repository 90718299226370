export default function handShadow(data: any) {
  const shadow = computed(() => {
    if (!data.value.transform.shadow) {
      return 'none';
    }
    const { type, horizontal, vertical, blur, spread, color, inset } = data.value.transform.shadow;
    if (type === 'none') {
      return 'none';
    }
    const shadowStr = `${horizontal}px ${vertical}px ${blur}px ${spread}px ${color} ${
      inset ? 'inset' : ''
    }`;
    return shadowStr;
  });

  const textShadow = computed(() => {
    const textShadow = data.value.params.textShadow;
    return textShadow
      ? `${textShadow.horizontal}px ${textShadow.vertical}px ${textShadow.blur}px ${textShadow.color}`
      : 'none';
  });
  return { shadow, textShadow };
}
