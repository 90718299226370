<template>
  <transition @enter="enter" @leave="leave" :css="false" appear v-if="edit">
    <VueDragResize
      v-if="show && elementShow"
      :parentScaleX="scale"
      :parentScaleY="scale"
      :parentW="width"
      :parentH="height"
      :w="draggableSize.w"
      :h="draggableSize.h"
      :x="draggableSize.x"
      :y="draggableSize.y"
      :isActive="isActive"
      :preventActiveBehavior="true"
      :z="data.index"
      :minw="0"
      :minh="0"
      @clicked="handleDragClick"
      @resizing="handleDragResize"
      @dragging="handleDragResize"
      :style="{
        transformOrigin: data.animation.transformOrigin
          ? data.animation.transformOrigin
          : 'center center',
      }"
    >
      <div class="time-wrapper" :style="timeStyle">
        <CircleProgress
          v-if="
            timeParamsValue.type === TimeType.Down &&
            progressShow &&
            progressPrarms.type === ProcessType.Filled
          "
          :width="elementWidth"
          :border-width="progressPrarms ? +progressPrarms?.borderWidth : undefined"
          :active-color="progressPrarms?.activeColor"
          :color="progressPrarms?.color"
          :background="progressPrarms?.background"
          :rate="downProgress"
          :shadow="progressShadow"
        />
        <CircleProgress1
          v-if="
            timeParamsValue.type === TimeType.Down &&
            progressShow &&
            progressPrarms.type === ProcessType.Gradient
          "
          :width="elementWidth"
          :border-width="progressPrarms ? +progressPrarms?.borderWidth : undefined"
          :active-color="progressPrarms?.activeColor"
          :color="progressPrarms?.color"
          :background="progressPrarms?.background"
          :rate="downProgress"
          :shadow="progressShadow"
        />
        <CircleProgress2
          v-if="
            timeParamsValue.type === TimeType.Down &&
            progressShow &&
            progressPrarms.type === ProcessType.Alternating
          "
          :width="elementWidth"
          :border-width="progressPrarms ? +progressPrarms?.borderWidth : undefined"
          :active-color="progressPrarms?.activeColor"
          :color="progressPrarms?.color"
          :background="progressPrarms?.background"
          :rate="downProgress"
          :shadow="progressShadow"
        />
        <span v-for="(item, index) in timeValue" :key="index">{{ item }}</span>
      </div>
    </VueDragResize>
  </transition>
  <transition @enter="enter" @leave="leave" :css="false" appear v-else>
    <div
      class="time-wrapper"
      v-if="show && elementShow"
      :style="timeStyle"
      @click="handleDragClick"
    >
      <CircleProgress
        v-if="
          timeParamsValue.type === TimeType.Down &&
          progressShow &&
          progressPrarms.type === ProcessType.Filled
        "
        :width="elementWidth"
        :border-width="progressPrarms ? +progressPrarms?.borderWidth : undefined"
        :active-color="progressPrarms?.activeColor"
        :color="progressPrarms?.color"
        :background="progressPrarms?.background"
        :rate="downProgress"
        :shadow="progressShadow"
      />
      <CircleProgress1
        v-if="
          timeParamsValue.type === TimeType.Down &&
          progressShow &&
          progressPrarms.type === ProcessType.Gradient
        "
        :width="elementWidth"
        :border-width="progressPrarms ? +progressPrarms?.borderWidth : undefined"
        :active-color="progressPrarms?.activeColor"
        :color="progressPrarms?.color"
        :background="progressPrarms?.background"
        :rate="downProgress"
        :shadow="progressShadow"
      />
      <CircleProgress2
        v-if="
          timeParamsValue.type === TimeType.Down &&
          progressShow &&
          progressPrarms.type === ProcessType.Alternating
        "
        :width="elementWidth"
        :border-width="progressPrarms ? +progressPrarms?.borderWidth : undefined"
        :active-color="progressPrarms?.activeColor"
        :color="progressPrarms?.color"
        :background="progressPrarms?.background"
        :rate="downProgress"
        :shadow="progressShadow"
      />
      <span v-for="(item, index) in timeValue" :key="index">{{ item }}</span>
    </div>
  </transition>
</template>
<script lang="ts" setup>
import moment from 'moment';
import { PropType } from 'vue';
import VueDragResize from 'vue-drag-resize';

import CircleProgress from '@/overlay/components/elements/circle-progress.vue';
import CircleProgress1 from '@/overlay/components/elements/circle-progress1.vue';
import CircleProgress2 from '@/overlay/components/elements/circle-progress2.vue';
import { ProcessType } from '@/overlay/types/template';
import { IElement, IResizeData, TimeType } from '@/overlay/types/template';

import HandleAnimation from './animation';
import dragResize from './dragResize';
import HandleShadow from './shadow';

const props = defineProps({
  data: {
    type: Object as PropType<IElement>,
    require: true,
    default: () => ({}),
  },
  show: {
    type: Boolean,
    require: true,
    default: false,
  },
  scale: {
    type: Number,
    require: true,
  },
  width: {
    type: Number,
    require: true,
    default: 1920,
  },
  height: {
    type: Number,
    require: true,
    default: 1080,
  },
  isActive: {
    type: Boolean,
    require: true,
    default: false,
  },
  edit: {
    type: Boolean,
    default: false,
  },
  /** 正计时和倒计时是否需要自动计时 */
  autoTime: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (e: 'resize', value: IResizeData): void;
  (e: 'dragActive'): void;
}>();

const { data, show, scale, width, height, isActive, edit, autoTime } = toRefs(props);

const timeParamsValue = computed(() => {
  const paramsValue =
    typeof data.value.params.timeValue === 'string'
      ? JSON.parse(data.value.params.timeValue)
      : data.value.params.timeValue;
  if (!paramsValue.type) {
    // 兼容老数据
    paramsValue.type = paramsValue.up ? TimeType.Up : TimeType.Down;
  }
  return paramsValue;
});

const timeWatchValue = computed(() => {
  return data.value.params.timeValue;
});

watch(timeWatchValue, () => {
  timeInit();
});

const elementShow = computed(() => {
  return typeof data.value.show === 'boolean' ? data.value.show : JSON.parse(data.value.show);
});

const lineHeight = computed(() => {
  return data.value.params.lineHeight ? +data.value.params.lineHeight : 1.5;
});

const isBolder = computed(() => {
  const bolder = data.value.params.isBolder;
  if (typeof bolder === 'boolean') {
    return bolder;
  } else {
    return bolder ? JSON.parse(bolder) : true;
  }
});

const isSeparator = computed(() => {
  const hasSeparator = data.value.params.isSeparator;
  if (typeof hasSeparator === 'boolean') {
    return hasSeparator;
  } else {
    return hasSeparator ? JSON.parse(hasSeparator) : true;
  }
});

const elementWidth = computed(() => {
  return (width.value * data.value.transform.width) / 100;
});

const progressPrarms = computed(() => {
  return data.value.params.progress;
});

const progressShadow = computed(() => {
  const shadow = progressPrarms.value?.shadow;
  if (typeof shadow === 'boolean') {
    return shadow;
  } else {
    return shadow ? JSON.parse(shadow) : true;
  }
});

const progressShow = computed(() => {
  const show = progressPrarms.value?.show;
  if (typeof show === 'boolean') {
    return show;
  } else {
    return show ? JSON.parse(show) : false;
  }
});

const timeStyle = computed(() => {
  const style: any = {
    fontSize: getTextFontSize(),
    fontFamily: data.value.params.fontFamily,
    color: data.value.params.color,
    background: data.value.params.background || '#ffffff00',
    lineHeight: lineHeight.value,
    'font-weight': isBolder.value ? 'bolder' : 'normal',
    'justify-content': isSeparator.value ? 'center' : 'space-around',
    zIndex: data.value.index,
    transform: `rotate(${data.value.transform.rotate || 0}deg) skew(${
      data.value.transform.skewX || 0
    }deg,${data.value.transform.skewY || 0}deg)`,
    boxShadow: shadow.value,
    textShadow: textShadow.value,
    transformOrigin: data.value.animation.transformOrigin
      ? data.value.animation.transformOrigin
      : 'center center',
  };
  if (!edit.value) {
    style.width = data.value.transform.width + '%';
    style.height = data.value.transform.height + '%';
    style.top = data.value.transform.y + '%';
    style.left = data.value.transform.x + '%';
  }
  return style;
});

const getTextFontSize = () => {
  const size = (height.value * data.value.transform.height) / 100 / lineHeight.value + 'px';
  return size;
};

const { enter, leave } = HandleAnimation(data, width, height);
const { shadow, textShadow } = HandleShadow(data);
const { draggableSize, handleDragResize, handleDragClick } = dragResize(data, width, height, emit);

const days = ref(0);
const hours = ref(0);
const minutes = ref(0);
const seconds = ref(0);
const currentTime = ref('');

/** 倒计时圆形动画的进度 */
const downProgress = computed(() => {
  if (hours.value === 0 && minutes.value === 0 && seconds.value === 0) return 1;
  const currentTotal = hours.value * 3600 + minutes.value * 60 + seconds.value;
  const downTotal = timeParamsValue.value.downTotal || 0;
  return downTotal > 0 ? 1 - currentTotal / downTotal : 0;
});

const numberFormat = (num: number) => {
  return num > 9 ? '' + num : '0' + num;
};

const timeValue = computed(() => {
  let timeStr: string;
  if (timeParamsValue.value.type === TimeType.Current) {
    timeStr = currentTime.value;
  } else {
    let format = timeParamsValue.value.format;
    let daysValue = format.indexOf('dd') < 0 ? '' : numberFormat(days.value);
    let hourValue = format.indexOf('hh') < 0 ? '' : numberFormat(hours.value);
    let minutesValue = format.indexOf('mm') < 0 ? '' : numberFormat(minutes.value);
    let secondsValue = format.indexOf('ss') < 0 ? '' : numberFormat(seconds.value);
    format = format.replace('dd', daysValue);
    format = format.replace('hh', hourValue);
    format = format.replace('mm', minutesValue);
    format = format.replace('ss', secondsValue);
    timeStr = format;
  }
  return isSeparator.value ? [timeStr] : timeStr.split(':');
});

/** 正计时 */
const timeUp = () => {
  switch (timeParamsValue.value.format) {
    case 'ss':
      seconds.value++;
      break;
    case 'mm:ss':
      if (seconds.value === 59) {
        seconds.value = 0;
        minutes.value++;
      } else {
        seconds.value++;
      }
      break;
    default:
      if (seconds.value === 59) {
        seconds.value = 0;
        if (minutes.value === 59) {
          hours.value++;
          minutes.value = 0;
        } else {
          minutes.value++;
        }
      } else {
        seconds.value++;
      }
      break;
  }
};

/** 倒计时 */
const timeDown = () => {
  switch (timeParamsValue.value.format) {
    case 'dd':
    case 'dd:hh:mm:ss':
      if (days.value === 0 && hours.value === 0 && minutes.value === 0 && seconds.value === 0)
        return;
      if (seconds.value === 0) {
        seconds.value = 59;
        if (minutes.value === 0) {
          minutes.value = 59;
          if (hours.value === 0) {
            hours.value = 23;
            days.value--;
          } else {
            hours.value--;
          }
        } else {
          minutes.value--;
        }
      } else {
        seconds.value--;
      }
      break;
    case 'ss':
      if (seconds.value !== 0) {
        seconds.value--;
      }
      break;
    case 'mm:ss':
      if (minutes.value === 0 && seconds.value === 0) return;
      if (seconds.value === 0) {
        seconds.value = 59;
        minutes.value--;
      } else {
        seconds.value--;
      }
      break;
    default:
      if (hours.value === 0 && minutes.value === 0 && seconds.value === 0) return;
      if (seconds.value === 0) {
        seconds.value = 59;
        if (minutes.value === 0) {
          minutes.value = 59;
          hours.value--;
        } else {
          minutes.value--;
        }
      } else {
        seconds.value--;
      }
      break;
  }
};

const timeChange = () => {
  if (timeParamsValue.value.up) {
    timeUp();
  } else {
    timeDown();
  }
};

/** 倒计时和正计时处理 */
const countHandle = () => {
  hours.value = timeParamsValue.value.hours;
  minutes.value = timeParamsValue.value.minutes;
  seconds.value = timeParamsValue.value.seconds;
};

/** 离结束时间还有多久处理 */
const endDayHandle = () => {
  const endTime = timeParamsValue.value.endTime
    ? timeParamsValue.value.endTime
    : moment().valueOf();
  const duration = (endTime - moment().valueOf()) / 1000;
  if (duration > 0) {
    switch (timeParamsValue.value.format) {
      case 'ss':
        days.value = 0;
        hours.value = 0;
        minutes.value = 0;
        seconds.value = Math.floor(duration);
        break;
      case 'dd':
      case 'dd:hh:mm:ss':
        days.value = Math.floor(duration / 3600 / 24);
        const remainTime = duration % (3600 * 24);
        hours.value = Math.floor(remainTime / 3600);
        minutes.value = Math.floor((remainTime / 60) % 60);
        seconds.value = Math.floor(remainTime % 60);
        break;
      case 'mm:ss':
        days.value = 0;
        hours.value = 0;
        minutes.value = Math.floor(duration / 60);
        seconds.value = Math.floor(duration % 60);
        break;
      default:
        days.value = 0;
        hours.value = Math.floor(duration / 3600);
        minutes.value = Math.floor((duration / 60) % 60);
        seconds.value = Math.floor(duration % 60);
        break;
    }
  } else {
    days.value = 0;
    hours.value = 0;
    minutes.value = 0;
    seconds.value = 0;
  }
};
// eslint-disable-next-line
let worker: Nullable<Worker> = null;
const timeInit = () => {
  if (timeParamsValue.value.type === TimeType.End) {
    endDayHandle();
  } else {
    countHandle();
  }
  if (!worker) {
    const code = `setInterval(() => postMessage(1), 1000);`;
    worker = new Worker(URL.createObjectURL(new Blob([code])));
    if (timeParamsValue.value.type === TimeType.Current) {
      currentTime.value = moment().format(timeParamsValue.value.format);
    } else if (timeParamsValue.value.type === TimeType.End && timeParamsValue.value.play) {
      timeChange();
    }
    worker.onmessage = () => {
      if (timeParamsValue.value.type === TimeType.Current) {
        currentTime.value = moment().format(timeParamsValue.value.format);
      } else if (timeParamsValue.value.play && timeParamsValue.value.type === TimeType.End) {
        timeChange();
      } else if (timeParamsValue.value.play && autoTime.value) {
        // 预览中的正计时和倒计时不需要自动计时
        timeChange();
      } else {
        clearWorker();
      }
    };
  }
};

const clearWorker = () => {
  if (worker) {
    worker.terminate();
    worker = null;
  }
};

onMounted(() => {
  timeInit();
});

onBeforeUnmount(() => {
  clearWorker();
});
</script>
<style lang="scss" scoped>
.time-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
