<script setup lang="ts">
const props = defineProps({
  rate: {
    type: Number,
    require: true,
    default: 0,
  },
  width: {
    type: Number,
    require: true,
    default: 400,
  },
  borderWidth: {
    type: Number,
    default: 20,
  },
  activeColor: {
    type: String,
    default: '#4b0101',
  },
  color: {
    type: String,
    default: '#960202',
  },
  background: {
    type: String,
    default: '#a2a2a200',
  },
  shadow: {
    type: Boolean,
    default: true,
  },
});

const { rate, width, borderWidth, activeColor, color, background, shadow } = toRefs(props);

const baseCircleStyle = computed(() => {
  return {
    width: width.value + 'px',
    height: width.value + 'px',
  };
});

const pathStyle = computed(() => {
  return {
    transform: `translate(${width.value / 2}px, ${width.value / 2}px)`,
  };
});

const radius = computed(() => {
  const num = shadow.value ? 4 : 0;
  return (width.value - borderWidth.value) / 2 - num;
});

const path = computed(() => {
  // 0.99999 防止起点和终点位置相同，无法画出圆
  const progress = rate.value === 0 ? 0.99999 : 1 - rate.value;
  // 当前进度对应的角度值
  const angle = progress * 360;
  // 当前角度对应的弧度值
  const radian = angle * (Math.PI / 180);
  // 极坐标转换成直角坐标
  const x = (Math.sin(radian) * radius.value).toFixed(2);
  const y = -(Math.cos(radian) * radius.value).toFixed(2);
  // 画大/小角弧度
  const lenghty = angle > 180 ? 1 : 0;
  // path 路径
  const d = `M 0 ${-radius.value} A ${radius.value} ${radius.value} 0 ${lenghty} 1 ${x} ${y}`;
  return d;
});
</script>

<template>
  <div class="circle-progress" :style="baseCircleStyle">
    <svg>
      <circle
        :r="radius"
        :cx="width / 2"
        :cy="width / 2"
        :fill="background"
        :stroke-width="borderWidth"
        :stroke="activeColor"
      />
      <circle
        v-if="shadow"
        class="shadow-box"
        :r="radius"
        :cx="width / 2"
        :cy="width / 2"
        fill="none"
        :stroke-width="borderWidth"
        :stroke="activeColor"
      />
      <path :style="pathStyle" :d="path" fill="none" :stroke-width="borderWidth" :stroke="color" />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.circle-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);

  svg {
    width: 100%;
    height: 100%;
  }

  .shadow-box {
    filter: drop-shadow(0 0 4px #fff);
  }
}
</style>
