<template>
  <transition @enter="enter" @leave="leave" :css="false" appear v-if="edit">
    <VueDragResize
      v-if="show && elementShow"
      :parentScaleX="scale"
      :parentScaleY="scale"
      :parentW="width"
      :parentH="height"
      :w="draggableSize.w"
      :h="draggableSize.h"
      :x="draggableSize.x"
      :y="draggableSize.y"
      :isActive="isActive"
      :preventActiveBehavior="true"
      :z="data.index"
      :minw="0"
      :minh="0"
      @clicked="handleDragClick"
      @resizing="handleDragResize"
      @dragging="handleDragResize"
      :style="{
        transformOrigin: data.animation.transformOrigin
          ? data.animation.transformOrigin
          : 'center center',
      }"
    >
      <div class="score-wrapper" :style="scoreStyle">
        <transition-group
          name="staggered-fade"
          tag="ul"
          :css="false"
          @before-enter="beforeEnter"
          @enter="listEnter"
          @leave="listLeave"
        >
          <li
            v-for="(item, i) in scoreList"
            :key="i"
            :style="i + 1 === scoreValue.current ? activeStyle : { width: scoreWidth }"
          >
            <span v-for="(num, j) in item" :key="j + '' + num">{{ num }}</span>
          </li>
        </transition-group>
      </div>
    </VueDragResize>
  </transition>
  <transition @enter="enter" @leave="leave" :css="false" appear v-else>
    <div
      class="score-wrapper"
      :style="scoreStyle"
      v-if="show && elementShow"
      @click="handleDragClick"
    >
      <transition-group
        name="staggered-fade"
        tag="ul"
        :css="false"
        @before-enter="beforeEnter"
        @enter="listEnter"
        @leave="listLeave"
      >
        <li
          v-for="(item, i) in scoreList"
          :key="i"
          :style="i + 1 === scoreValue.current ? activeStyle : { width: scoreWidth }"
        >
          <span v-for="(num, j) in item" :key="j + '' + num">{{ num }}</span>
        </li>
      </transition-group>
    </div>
  </transition>
</template>
<script lang="ts" setup>
import { PropType } from 'vue';
import HandleAnimation from './animation';
import gsap from 'gsap';
import dragResize from './dragResize';
import HandleShadow from './shadow';
import VueDragResize from 'vue-drag-resize';
import { IResizeData, IElement } from '@/overlay/types/template';

const props = defineProps({
  data: {
    type: Object as PropType<IElement>,
    require: true,
    default: () => ({}),
  },
  show: {
    type: Boolean,
    require: true,
    default: false,
  },
  width: {
    type: Number,
    require: true,
    default: 1920,
  },
  height: {
    type: Number,
    require: true,
    default: 1080,
  },
  scale: {
    type: Number,
    require: true,
  },
  isActive: {
    type: Boolean,
    require: true,
    default: false,
  },
  edit: {
    type: Boolean,
    default: false,
  },
});

const beforeEnter = (el: any) => {
  el.style.opacity = 0;
  el.style.width = 0;
};
const listEnter = (el: any, done: any) => {
  gsap.to(el, {
    opacity: 1,
    width: scoreWidth.value,
    delay: el.dataset.index * 0.15,
    onComplete: done,
  });
};
const listLeave = (el: any, done: any) => {
  gsap.to(el, {
    opacity: 0,
    width: 0,
    delay: el.dataset.index * 0.15,
    onComplete: done,
  });
};
const emit = defineEmits<{
  (e: 'resize', value: IResizeData): void;
  (e: 'dragActive'): void;
}>();

const { data, show, scale, width, height, isActive, edit } = toRefs(props);
const { enter, leave } = HandleAnimation(data, width, height);

const elementShow = computed(() => {
  return typeof data.value.show === 'boolean' ? data.value.show : JSON.parse(data.value.show);
});

const scoreValue = computed(() => {
  if (typeof data.value.params.scoreValue === 'string') {
    return JSON.parse(data.value.params.scoreValue);
  } else {
    return data.value.params.scoreValue;
  }
});

const lineHeight = computed(() => {
  return data.value.params.lineHeight ? +data.value.params.lineHeight : 1.5;
});

const isBolder = computed(() => {
  const bolder = data.value.params.isBolder;
  if (typeof bolder === 'boolean') {
    return bolder;
  } else {
    return bolder ? JSON.parse(bolder) : true;
  }
});

const scoreStyle = computed(() => {
  const style: any = {
    background: data.value.params.background,
    lineHeight: lineHeight.value,
    'font-weight': isBolder.value ? 'bolder' : 'normal',
    color: data.value.params.textColor,
    fontFamily: data.value.params.fontFamily,
    fontSize: getTextFontSize(),
    zIndex: data.value.index,
    transform: `rotate(${data.value.transform.rotate || 0}deg) skew(${
      data.value.transform.skewX || 0
    }deg,${data.value.transform.skewY || 0}deg)`,
    boxShadow: shadow.value,
    textShadow: textShadow.value,
    transformOrigin: data.value.animation.transformOrigin
      ? data.value.animation.transformOrigin
      : 'center center',
  };
  if (!edit.value) {
    style.height = data.value.transform.height + '%';
    style.top = data.value.transform.y + '%';
    style.left = data.value.transform.x + '%';
  }
  return style;
});
const activeStyle = computed(() => {
  const style = {
    width: scoreWidth.value,
    background: data.value.params.activeColor,
    color: data.value.params.activeTextColor,
  };
  return style;
});
const scoreList = computed(() => {
  return scoreValue.value.list.slice(0, scoreValue.value.current);
});

const scoreWidth = computed(() => {
  return (width.value * data.value.transform.width) / 100 / scoreValue.value.list.length + 'px';
});

const getTextFontSize = () => {
  const size = (height.value * data.value.transform.height) / 100 / lineHeight.value / 2 + 'px';
  return size;
};

const { draggableSize, handleDragResize, handleDragClick } = dragResize(data, width, height, emit);
const { shadow, textShadow } = HandleShadow(data);
</script>
<style lang="scss" scoped>
.score-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  > ul {
    display: flex;
  }

  li {
    display: flex;
    flex-direction: column;
    text-align: center;

    span {
      animation: fadeIn 1s ease-in;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }
}
</style>
