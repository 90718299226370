<script setup lang="ts">
const props = defineProps({
  width: {
    type: Number,
    require: true,
    default: 800,
  },
  offset: {
    type: Number,
    default: 43,
  },
  background: {
    type: String,
    default: 'linear-gradient(227deg, #00FEFF 0%, #3ABDFF 55%, #7704FF 100%)',
  },
});

const { width, offset } = toRefs(props);

const baseCircleStyle = computed(() => {
  return {
    width: width.value + 'px',
    height: width.value + 'px',
  };
});
</script>

<template>
  <div class="circle-progress" :style="baseCircleStyle">
    <svg>
      <defs>
        <linearGradient id="color1" x1="0%" y1="0%" x2="0" y2="100%" gradientUnits="userSpaceOnUse">
          <!-- animateTransform Safari 不支持  -->
          <animateTransform
            attributeName="gradientTransform"
            type="rotate"
            :from="`0 ${width / 2} ${width / 2}`"
            :to="`360 ${width / 2} ${width / 2}`"
            dur="4s"
            repeatCount="indefinite"
          ></animateTransform>
          <stop offset="0" stop-color="#00FEFF" />
          <stop offset="0.55" stop-color="#3ABDFF" />
          <stop offset="1" stop-color="#7704FF" />
        </linearGradient>
      </defs>
      <g stroke="url(#color1)">
        <line
          v-for="(item, index) in 120"
          :key="index"
          :x1="width / 2 + (width / 2) * Math.cos((3 * item * Math.PI) / 180)"
          :y1="width / 2 + (width / 2) * Math.sin((3 * item * Math.PI) / 180)"
          :x2="width / 2 + (width / 2 - offset) * Math.cos((3 * item * Math.PI) / 180)"
          :y2="width / 2 + (width / 2 - offset) * Math.sin((3 * item * Math.PI) / 180)"
          style="stroke-width: 4"
        />
      </g>
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.circle-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -55%);

  svg {
    width: 100%;
    height: 100%;
  }
}
</style>
