<template>
  <transition @enter="enter" @leave="leave" :css="false" appear v-if="edit">
    <VueDragResize
      v-if="show && elementShow"
      :parentScaleX="scale"
      :parentScaleY="scale"
      :parentW="width"
      :parentH="height"
      :w="draggableSize.w"
      :h="draggableSize.h"
      :x="draggableSize.x"
      :y="draggableSize.y"
      :isActive="isActive"
      :preventActiveBehavior="true"
      :z="data.index"
      :minw="0"
      :minh="0"
      @clicked="handleDragClick"
      @resizing="handleDragResize"
      @dragging="handleDragResize"
      :style="{
        transformOrigin: data.animation.transformOrigin
          ? data.animation.transformOrigin
          : 'center center',
      }"
    >
      <div class="image-wrapper" :style="rectStyle">
        <img
          :class="[data.params.cover && JSON.parse(data.params.cover) ? 'cover' : 'contain']"
          :src="data.params.url"
          alt=""
        />
      </div>
    </VueDragResize>
  </transition>
  <transition @enter="enter" @leave="leave" :css="false" appear v-else>
    <div
      class="image-wrapper"
      :style="rectStyle"
      v-if="show && elementShow"
      @click="handleDragClick"
    >
      <img
        :class="[data.params.cover && JSON.parse(data.params.cover) ? 'cover' : 'contain']"
        :src="data.params.url"
        alt=""
      />
    </div>
  </transition>
</template>
<script lang="ts" setup>
import { PropType } from 'vue';
import HandleAnimation from './animation';
import HandleShadow from './shadow';
import dragResize from './dragResize';
import VueDragResize from 'vue-drag-resize';
import { IResizeData, IElement } from '@/overlay/types/template';

const props = defineProps({
  data: {
    type: Object as PropType<IElement>,
    require: true,
    default: () => ({}),
  },
  show: {
    type: Boolean,
    require: true,
    default: false,
  },
  scale: {
    type: Number,
    require: true,
  },
  width: {
    type: Number,
    require: true,
    default: 1920,
  },
  height: {
    type: Number,
    require: true,
    default: 1080,
  },
  isActive: {
    type: Boolean,
    require: true,
    default: false,
  },
  edit: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (e: 'resize', value: IResizeData): void;
  (e: 'dragActive'): void;
}>();

const { data, show, scale, width, height, isActive, edit } = toRefs(props);

const elementShow = computed(() => {
  return typeof data.value.show === 'boolean' ? data.value.show : JSON.parse(data.value.show);
});

const rectStyle = computed(() => {
  const style: any = {
    zIndex: data.value.index,
    transform: `rotate(${data.value.transform.rotate || 0}deg) skew(${
      data.value.transform.skewX || 0
    }deg,${data.value.transform.skewY || 0}deg)`,
    borderTopLeftRadius: data.value.params.borderRadiusTL
      ? data.value.params.borderRadiusTL + 'px'
      : '0',
    borderTopRightRadius: data.value.params.borderRadiusTR
      ? data.value.params.borderRadiusTR + 'px'
      : '0',
    borderBottomRightRadius: data.value.params.borderRadiusBR
      ? data.value.params.borderRadiusBR + 'px'
      : '0',
    borderBottomLeftRadius: data.value.params.borderRadiusBL
      ? data.value.params.borderRadiusBL + 'px'
      : '0',
    boxShadow: shadow.value,
    transformOrigin: data.value.animation.transformOrigin
      ? data.value.animation.transformOrigin
      : 'center center',
  };
  if (!edit.value) {
    style.width = data.value.transform.width + '%';
    style.height = data.value.transform.height + '%';
    style.top = data.value.transform.y + '%';
    style.left = data.value.transform.x + '%';
  }
  return style;
});

const { enter, leave } = HandleAnimation(data, width, height);
const { shadow } = HandleShadow(data);
const { draggableSize, handleDragResize, handleDragClick } = dragResize(data, width, height, emit);
</script>
<style lang="scss" scoped>
.image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 1;

  img {
    &.cover {
      width: 100%;
      height: 100%;
    }

    &.contain {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
