import { Ref } from 'vue';
import { IResizeData } from '@/overlay/types/template';
export default function dragResize(data: any, width: Ref<number>, height: Ref<number>, emit: any) {
  const draggableSize = computed(() => {
    return {
      x: Math.round((data.value.transform.x * width.value) / 100),
      y: Math.round((data.value.transform.y * height.value) / 100),
      w: Math.round((data.value.transform.width * width.value) / 100),
      h: Math.round((data.value.transform.height * height.value) / 100),
    };
  });
  const handleDragResize = (val: IResizeData) => {
    const actionDom = document.activeElement!.tagName;
    if (val.width >= 0 && val.height >= 0 && actionDom !== 'INPUT') {
      emit('resize', val);
    }
  };

  const handleDragClick = () => {
    emit('dragActive');
  };
  return { draggableSize, handleDragResize, handleDragClick };
}
