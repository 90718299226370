import gsap from 'gsap';
import type { Ref } from 'vue';

export default function handAnimation(
  data: any,
  layerWidth: Ref<number>,
  layerHeight: Ref<number>,
) {
  const handleAnimation = (animation: any) => {
    const options: any = {
      duration: animation.duration,
      immediateRender: true,
      ease: animation.ease,
      delay: animation.delay ? animation.delay : 0,
    };
    if (animation.mode === 'translate') {
      const { x, y, width, height } = data.value.transform;
      const finalX =
        x && Math.abs(x) > 100 ? (Math.abs(x) * layerWidth.value) / 100 + width : layerWidth.value;
      const finalY =
        y && Math.abs(y) > 100
          ? (Math.abs(y) * layerHeight.value) / 100 + height
          : layerHeight.value;
      if (animation.type === 'left') {
        options.x = `-${finalX}px`;
      } else if (animation.type === 'right') {
        options.x = `${finalX}px`;
      } else if (animation.type === 'up') {
        options.y = `-${finalY}px`;
      } else if (animation.type === 'down') {
        options.y = `${finalY}px`;
      }
    } else if (animation.mode === 'fade') {
      options.autoAlpha = 0;
    } else if (animation.mode === 'scale') {
      if (animation.type === 'X and Y') {
        options.scale = 0;
      } else if (animation.type === 'X') {
        options.scaleX = 0;
      } else if (animation.type === 'Y') {
        options.scaleY = 0;
      }
    } else if (animation.mode === 'rotate') {
      if (animation.type === 'X+') {
        options.transform = 'rotate3d(1,0,0,90deg)';
      } else if (animation.type === 'X-') {
        options.transform = 'rotate3d(-1,0,0,90deg)';
      } else if (animation.type === 'Y+') {
        options.transform = 'rotate3d(0,1,0,90deg)';
      } else if (animation.type === 'Y-') {
        options.transform = 'rotate3d(0,-1,0,90deg)';
      }
    } else if (animation.mode === 'none') {
      options.duration = 0;
      options.delay = 0;
    }
    return options;
  };

  const enter = (el: any, done: any) => {
    const options = handleAnimation(data.value.animation.in);
    gsap.from(el, {
      ...options,
      onComplete: done,
    });
  };

  const leave = (el: any, done: any) => {
    const options = handleAnimation(data.value.animation.out);
    gsap.to(el, {
      ...options,
      onComplete: done,
    });
  };

  return { enter, leave };
}
