<template>
  <transition @enter="enter" @leave="leave" :css="false" appear v-if="edit">
    <VueDragResize
      v-if="show && elementShow"
      :parentScaleX="scale"
      :parentScaleY="scale"
      :parentW="width"
      :parentH="height"
      :w="draggableSize.w"
      :h="draggableSize.h"
      :x="draggableSize.x"
      :y="draggableSize.y"
      :isActive="isActive"
      :preventActiveBehavior="true"
      :z="data.index"
      :minw="0"
      :minh="0"
      @clicked="handleDragClick"
      @resizing="handleDragResize"
      @dragging="handleDragResize"
      :style="{
        transformOrigin: data.animation.transformOrigin
          ? data.animation.transformOrigin
          : 'center center',
      }"
    >
      <div class="marquee-wrapper" :style="marqueeStyle">
        <p
          :class="id"
          :style="{
            paddingRight: getTextFontSize(),
          }"
        >
          {{ data.params.content }}
        </p>
      </div>
    </VueDragResize>
  </transition>
  <transition @enter="enter" @leave="leave" :css="false" appear v-else>
    <div
      class="marquee-wrapper"
      :style="marqueeStyle"
      v-if="show && elementShow"
      @click="handleDragClick"
    >
      <p
        :class="id"
        :style="{
          paddingRight: getTextFontSize(),
        }"
      >
        {{ data.params.content }}
      </p>
    </div>
  </transition>
</template>
<script lang="ts" setup>
import { PropType } from 'vue';
import HandleAnimation from './animation';
import HandleShadow from './shadow';
import gsap from 'gsap';
import dragResize from './dragResize';
import VueDragResize from 'vue-drag-resize';
import { IResizeData, IElement } from '@/overlay/types/template';

const props = defineProps({
  data: {
    type: Object as PropType<IElement>,
    require: true,
    default: () => ({}),
  },
  show: {
    type: Boolean,
    require: true,
    default: false,
  },
  scale: {
    type: Number,
    require: true,
  },
  width: {
    type: Number,
    require: true,
    default: 1920,
  },
  height: {
    type: Number,
    require: true,
    default: 1080,
  },
  isActive: {
    type: Boolean,
    require: true,
    default: false,
  },
  edit: {
    type: Boolean,
    default: false,
  },
  id: String,
});

const emit = defineEmits<{
  (e: 'resize', value: IResizeData): void;
  (e: 'dragActive'): void;
}>();

const { data, show, scale, width, height, isActive, edit, id } = toRefs(props);

watch(
  () => data.value.params.onceTime,
  () => {
    animationInit();
  },
);
watch(
  () => data.value.params.repeat,
  () => {
    animationInit();
  },
);
watch(edit, () => {
  animationInit();
});
watch(show, val => {
  if (val) {
    animationInit();
  }
});
watch(
  () => data.value.show,
  val => {
    if (val) {
      animationInit();
    }
  },
);

const elementShow = computed(() => {
  return typeof data.value.show === 'boolean' ? data.value.show : JSON.parse(data.value.show);
});

const marqueeStyle = computed(() => {
  const style: any = {
    color: data.value.params.color,
    fontFamily: data.value.params.fontFamily,
    fontWeight: data.value.params.isBolder ? 'bolder' : 'normal',
    lineHeight: +data.value.params.lineHeight,
    fontStyle: data.value.params.fontStyle,
    fontSize: getTextFontSize(),
    zIndex: data.value.index,
    transform: `rotate(${data.value.transform.rotate || 0}deg) skew(${
      data.value.transform.skewX || 0
    }deg,${data.value.transform.skewY || 0}deg)`,
    boxShadow: shadow.value,
    textShadow: textShadow.value,
    transformOrigin: data.value.animation.transformOrigin
      ? data.value.animation.transformOrigin
      : 'center center',
  };
  if (!edit.value) {
    style.width = data.value.transform.width + '%';
    style.height = data.value.transform.height + '%';
    style.top = data.value.transform.y + '%';
    style.left = data.value.transform.x + '%';
  }
  return style;
});

const getTextFontSize = () => {
  const size =
    (height.value * data.value.transform.height) / 100 / +data.value.params.lineHeight + 'px';
  return size;
};

const { enter, leave } = HandleAnimation(data, width, height);

const { draggableSize, handleDragResize, handleDragClick } = dragResize(data, width, height, emit);
const { shadow, textShadow } = HandleShadow(data);

let marquee: any = null;

const animationInit = async () => {
  if (!(show.value && elementShow.value)) {
    return;
  }
  await nextTick();
  if (!marquee) {
    return;
  }
  marquee.kill();
  marquee = gsap.timeline({ repeat: data.value.params.repeat ? -1 : 0, repeatDelay: 1 });
  marquee.fromTo(
    '.' + id?.value,
    {
      marginLeft: '100%',
      translateX: '0',
    },
    {
      marginLeft: '0',
      translateX: '-100%',
      duration: data.value.params.onceTime,
      ease: 'none',
    },
  );
};

onMounted(() => {
  marquee = gsap.timeline({ repeat: -1, repeatDelay: 1 });
  animationInit();
});
</script>
<style lang="scss" scoped>
.marquee-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 1;

  p {
    width: fit-content;
    margin-left: 100%;
    white-space: nowrap;
  }
}
</style>
